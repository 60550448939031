$error-font-color: #ff5c71;

.termsOfUse {
  .checkbox {
    display: inline-flex;
    margin-top: 1.5em;
    text-align: left;

    &:first {
      margin-top: 0;
    }
  }

  .termsModal {
    display: inline-block;
  }

  .checkboxPadding {
    padding-right: 1em;
  }
}

.agreementText {
  overflow: scroll;
  text-align: left;
}

.errorMessage {
  color: $error-font-color;
  font-size: 0.75em;
  margin-top: 0.35em;
}
