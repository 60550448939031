@import "./baseline.scss";

[data-react-component-name],
.react-element-wrapper {
  font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  margin: 0;
  user-select: none;
  -webkit-font-smoothing: antialiased;

  @include react-components-baseline(':not([class*="Mui"])');
}
