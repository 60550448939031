$white: #fff;
$shadow-color: rgba(0, 0, 0, 0.19);

.container {
  position: relative;
}

.suggestionContainer {
  background: $white;
  box-shadow: 0 4px 8px 0 $shadow-color;
  max-height: 260px;
  min-width: 150px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 2;
}

.suggestionList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.suggestion {
  font-weight: 700;
  padding: 1em 0.5em;
  white-space: normal;
}

.hightlight {
  color: var(--primary-color);
}
