$text-primary-color: var(--palette-text-primary, #1a1a1a);
$text-secondary-color: var(--palette-text-secondary, #555555);

@mixin react-components-baseline($scope) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &#{$scope} {
      color: $text-primary-color;
      font-size: 1.3rem;
      line-height: 1.7em;
      margin: 0;
    }
  }

  p {
    &#{$scope} {
      color: $text-secondary-color;
      margin: 0 0 0.5rem;
    }
  }
}
