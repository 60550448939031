button.select {
  font-size: 1.1875rem;
}

.pagination {
  :global {
    .MuiPaginationItem-root {
      border: 2px solid var(--primary-color);
      border-radius: 16px;
      width: 48px;
      height: 48px;
      font-weight: bold;
      font-size: 1.1875rem;
      color: var(--primary-color);
      margin-left: 1rem;
    }

    button.Mui-selected {
      background-color: var(--primary-color);
      color: var(--white);

      &:hover {
        background-color: var(--primary-color);
        color: var(--white);
      }
    }
  }
}

.modal {
  width: 304px;
}
