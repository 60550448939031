.svg {
  transform: rotate(-90deg);
  /* height is width, since we're rotating it */
  height: 100%;
  max-height: 350px;

  :global {
    .cls-1 {
      fill: #666b6f;
    }
    .cls-1,
    .cls-2,
    .cls-3,
    .cls-5,
    .cls-6,
    .cls-8 {
      fill-rule: evenodd;
    }
    .cls-2,
    .cls-9 {
      fill: var(--primary-color);
    }
    .cls-3,
    .cls-4 {
      fill: #fff;
    }
    .cls-5 {
      opacity: 0.5;
    }
    .cls-6 {
      fill: #b6b6b6;
    }
    .cls-7,
    .cls-8 {
      fill: #c5c5c5;
    }
  }
}
