$list-item-bg: rgba(211, 214, 229, 0.15);

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.listEven {
  > li:nth-child(even) {
    background-color: $list-item-bg;
  }
}

.listOdd {
  > li:nth-child(odd) {
    background-color: $list-item-bg;
  }
}
