.textField {
  margin: 0;
  display: inline-block;
  margin-left: var(--small-spacing);
}

input.input {
  height: auto;
  width: 6rem;
}

.container {
  display: flex;
  align-items: center;
}
