$loading-overlay: rgba(0, 0, 0, 0.3);
$loader-size: 100px;

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.root {
  visibility: hidden;
  background: $loading-overlay;
  bottom: 0;
  content: "";
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
  display: flex;

  &.visible {
    visibility: initial;
  }
}
