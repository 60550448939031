$white-color: #fff;
$radio-button-height: 3.6em;
$base-spacing: 1em;

.question {
  border: 2px solid var(--base-border-color);
  border-radius: 4px;
  display: flex;
  overflow: hidden;
}

.errorText {
  color: var(--error-color);
  font-size: 0.8em;
  font-weight: 600;
}

.radioButton {
  color: var(--palette-text-secondary);
  display: flex;
  flex: 1 1 0;
  min-height: $radio-button-height;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  text-align: center;

  &:first-child {
    .radioButtonText {
      border-left: 0 none;
      padding-left: $base-spacing;
    }
  }

  &:last-child {
    .radioButtonText {
      padding-right: $base-spacing;
    }
  }

  .radioButtonInput {
    opacity: 0;
    position: absolute;

    &:checked + .radioButtonText {
      background: var(--primary-color);
      color: $white-color;
    }
  }

  .radioButtonText {
    align-items: center;
    border-left: 2px solid var(--base-border-color);
    display: flex;
    flex: 1 1 auto;
    font-size: 0.8em;
    justify-content: center;
    line-height: 1.2em;
    padding: 0.5em 0.25em;
  }

  @media (max-width: 360px) {
    .radioButtonText {
      font-size: 0.7em;
    }
  }
}
