.loader {
  border: 8px solid var(--primary-color);
  border-top: 8px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin: auto;
  animation: spin 3s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
