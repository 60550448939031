$border-width: 2px;

.label {
  border: $border-width solid var(--palette-grey-300);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  color: var(--palette-text-secondary);
  cursor: pointer;
  display: flex;
  flex: 1 1 0;
  justify-content: center;
  overflow: hidden;
  height: 3.15rem;
  padding: calc(var(--unit-spacing) * 0.7) 0;
  text-align: center;
  white-space: nowrap;

  + label {
    margin-left: 1em;
  }

  input {
    display: none;
  }
}

.active {
  border: $border-width solid var(--primary-color);
  color: var(--primary-color);
}
