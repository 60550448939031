$small-device-breakpoint: 320px;

.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.tableRow {
  border-bottom: 1px solid var(--palette-grey-100);
}

.rowContainer {
  &:last-child {
    .tableRow:last-child {
      border-bottom: 0;
    }
  }
}

.baseTableCell {
  padding: var(--base-spacing);

  &.left {
    text-align: left;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }
}

.tableHeadCell {
  color: var(--primary-gray-color);
  font-size: 0.8em;
  text-transform: uppercase;
}

.tableCell {
  font-weight: 400;
}

@media screen and (max-width: $small-device-breakpoint) {
  .table {
    font-size: 0.8rem;
  }
}
